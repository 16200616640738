<template>
    <!-- Error page-->
    <div class="misc-wrapper d-block error-page d-flex align-items-center">
        <div>
            <b-link class="brand-logo error-logo" to="/">
                <b-row >
                    <b-col cols="12" class="d-flex justify-content-center">
                        <b-img fluid width="300" :src="require('@/assets/images/logo/logo_only.svg')" alt="Error page"/>
                    </b-col>
                    <b-col cols="12" class="mt-2 d-flex justify-content-center">
                        <b-img fluid width="300" :src="require('@/assets/images/logo/logo_text_only.svg')" alt="Error page"/>
                    </b-col>
                </b-row>

            </b-link>

            <div class="misc-inner p-2 p-sm-3 mt-4" style="max-width: 100% !important;">


                <div class="w-100 text-center">
                    <h1 class="mb-1">
                        Strani ni mogoče najti️
                    </h1>
                    <h4 class="mt-2 mb-2">
                        Zahtevanega URL-ja ni bilo mogoče najti na tem strežniku.
                    </h4>

                    <b-button
                        pill
                        variant="primary"
                        class="mb-2 btn-sm-block shadow-none btn-no-hover-effects"
                        :to="{path:'/'}"
                    >
                        Nazaj domov
                    </b-button>

                <!-- image -->
                </div>
            </div>
        </div>
    </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
    import { BLink, BButton, BImg, BRow, BCol } from 'bootstrap-vue'
    import store from '@/store/index'

    export default {
        components: {
            BLink,
            BButton,
            BImg,
            BRow,
            BCol
        },
        data() {
            return {
                downImg: require('@/assets/images/pages/error.svg')
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.downImg = require('@/assets/images/pages/error-dark.svg')
                    return this.downImg
                }
                return this.downImg
            }
        }
    }
</script>

<style scoped>
.error-logo {
  position: relative !important;
  left: 0 !important;
}

</style>

<style lang="scss">
    @import '@core/scss/vue/pages/page-misc.scss';

    .error-page .btn-no-hover-effects{
        background: --primary !important;
        border: none !important;
    }

    .error-page .btn-no-hover-effects:hover, .btn-no-hover-effects:active, .btn-no-hover-effects:focus {
        background-color: --primary !important;
        border: none !important;
    }

</style>
